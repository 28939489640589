<template>
    <div class="px-3 md:px-0 pt-4">

        <loading-modal :isShow="isLoading"/>
        <div v-if="!isLoading">
            <p class="text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize mb-6">
                Promo
            </p>

            <div  class="shadow bg-white relative rounded-lg px-4 py-4">
                <img :src="promo.thumbnail" class="w-full rounded-lg" />
                <div class="flex space-x-5 items-center mt-2">
                    <div class="flex-grow flex-wrap ">
                        <h2 class="text-lg text-black font-semibold">{{ promo.judul }}</h2>
                        <p>{{ promo.kategori.judul }}</p>
                    </div>
                </div>

                <p class="mt-2" v-html="promo.deskripsi"/>

                <div class="mt-2 mb-4">
                    <div class="p-1 px-2 bg-blue-100 text-sm rounded text-blue-700 inline-block">
                        {{ promo.target_promo == 'sebagian' ? "Berlaku untuk beberapa paket" : "Berlaku untuk semua paket" }}
                    </div> 

                    <router-link :to="{
                        name : 'detailModul',
                        params : {
                            'singkatan_kategori': promo.kategori.singkatan.toLowerCase(),
                            'tipe_modul'        : 'berbayar',
                            'id_paket'          : data.id_paket
                        }
                    }" class=" flex items-center flex-row bg-white py-2 px-3 shadow rounded-lg my-2 transition-colors hover:border-gray-300"
                        v-for="(data, index) in promo.paket" :key="index">
                        <div class="w-16">
                            <div class="h-16 w-16 bg-yellow-100 rounded-full content-center flex items-center text-center">
                                <img src="../../assets/images/package.svg" class="h-10 w-10 mx-auto"/>
                            </div>
                        </div>

                        <div class="flex-grow ml-3 pt-1 pb-1">
                            <h2 class="font-semibold tracking-tight text-gray-800 text-xl">{{ data.nama_paket }}</h2>
                            <span class="mr-5 text-gray-700 text-based">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                                <span v-if="data.harga_coret != null" class=" font-bold tracking-wider text-red-500 mr-1"><s>{{ data.harga_coret | moneyFormat }}</s></span>
                                <span class="text-lg font-bold tracking-wider text-yellow-500">{{ data.harga_paket | moneyFormat }}</span>
                            </span>
                        </div>
                    </router-link>

                </div>

                <div class="flex mt-2 items-center">
                    <div class="flex-grow">
                        <p class="font-medium text-gray-500">Potongan</p>
                        <p class="text-lg text-black font-semibold">Rp. {{ promo.jumlah_potongan | moneyFormat }}</p>
                    </div>
                    <div class="flex-grow bg-blue-500 px-3 py-2 text-white font-medium text-lg rounded-lg relative font-mono" @click="copyPromoCode(promo.kode_promo)">
                        {{ promo.kode_promo }}
                        <div class="absolute right-1 bg-blue-600 top-1 bottom-1 text-sm w-10 rounded-lg flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

        </div>

   </div>
</template>
<script>
    import LoadingModal from '../../components/LoadingModal.vue';
    import { copyToClipboard, errorHandler, showErrorNotif } from '../../helpers/Tools';

    export default {
        name: 'PromoDetail',
        components: { LoadingModal,
        },
        data(){
            return {
                isLoading     : true,
                promo     : {},
            }
        },
        mounted() {
			this.getDetailPromo();
		},
		methods : {
            copyPromoCode (string) {
                copyToClipboard(this, string);
            },
            /**
             * 
             */
            async getDetailPromo() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getDetailPromo", {
                        id_promo : this.$route.params.id_promo
                    })
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.promo = result.data;
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

        }
    }
</script>
